import { Middleware, configureStore } from '@reduxjs/toolkit'
// import * as Sentry from '@sentry/react'
import { routerMiddleware } from 'connected-react-router'
import { pipe, propOr } from 'ramda'
import createSagaMiddleware from 'redux-saga'
import thunk, { ThunkAction } from 'redux-thunk'

import { history } from '../history'
import reducers from './reducers'
import sagas from './sagas'

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options listed below
// })
const middleware = [thunk, routerMiddleware(history)]
// const customMiddleware = store => next => action => { }

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { createLogger } = require('redux-logger')

  const logger: Middleware = createLogger({
    collapsed: true,
    colors: {
      prevState: () => '#9E9E9E',
      action: () => '#03A9F4',
      nextState: () => '#4CAF50',
      error: () => '#F20404',
      title: (action: { type: string }) => {
        return pipe(actionType => {
          return /fulfilled$/i.test(actionType)
            ? '#14c719'
            : /rejected$/i.test(actionType)
            ? '#c62c2c'
            : /pending$/i.test(actionType)
            ? '#d2c22e'
            : /clear$/i.test(actionType)
            ? '#11acc9'
            : '#FFF'
        })(propOr('', 'type', action))
      },
    },
  })

  middleware.push(logger)
}

function createStore(args = {}) {
  const sagaMiddleware = createSagaMiddleware({ context: { history, ...args } })
  const modifiedMiddlewares = [...middleware, sagaMiddleware]

  const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(modifiedMiddlewares),
  })

  sagaMiddleware.run(sagas)

  return store
}

export interface Action<T = any> {
  type: T
}

export type AppDispatch = ReturnType<typeof createStore>['dispatch']
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export { createStore, history }
