import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routerSelector} from '../redux/selector'
import {
  dissoc,
  equals,
  forEach,
  isEmpty,
  isNil,
  map,
  omit,
  path,
  pipe,
  prop,
  propOr,
  pickBy,
  pathOr,
  type,
  pick,
  includes,
  head,
} from 'ramda'
import { parse } from 'qs'
import { getPage } from '../helpers/get'
import queryString from 'query-string'
import { areObjsEq } from '../helpers/areObjsEq'

type Props = {
  queryIdNames: string[]
  actions: Actions
  filterDependencyKey?: string[]
  initialListFetch?: boolean
  defaultSize?: number
  provideSPSS?: true | { [key: string]: boolean }
  defaultQueryValue?: { [key: string]: boolean | number | string }
}

type Actions = {
  [key: string]: FilterFieldActions | Action | undefined
  listClear: Action
  filterAction: Action
  initialFetchData?: Action
  initialFetchDataClear?: Action
}

type Action = (payload?: any) => {
  type: string
  payload?: any
}

type s = {
  [key: string]: any
}
interface FilterFieldActions {
  fetchAction?: Action | Action[] | null
  clearAction?: Action | Action[] | null
  params?: s
}

type Query = {
  [key: string]: string
}

type Router = {
  pathname: string
  query: Query
  location: {
    key: string
    pathname: string
    search: string
    hash: string
    state: {
      [key: string]: any
    }
  }
}

interface IQueryIds {
  [key: string]: string | number | undefined
}

type IQueryIdsTuple<T> = [[keyof T], [T[keyof T]]]

type SPSS = {
  search?: string
  page?: number | string
  sort?: string
  size?: number | string
}

interface ICache<T, U> {
  [key: string]: T | SPSS | U | string | number | boolean | undefined
  spss: SPSS
  queryIds: T
  fetched: U
}

interface IGetPageParams extends IQueryIds, SPSS { }

type GetQueryParamsArgsTypes = {
  provideSPSS: Props['provideSPSS']
  getPageParams: IGetPageParams
  queryIds: IQueryIds
}

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> }

type Cast<X, Y> = X extends Y ? X : Y

type FromEntries<T> = T extends [infer Key, any][]
  ? { [K in Cast<Key, PropertyKey>]: Extract<ArrayElement<T>, [K, any]>[1] }
  : { [key in string]: any }

export type FromEntriesWithReadOnly<T> = FromEntries<DeepWriteable<T>>

declare global {
  interface ObjectConstructor {
    fromEntries<T>(obj: T): FromEntriesWithReadOnly<T>
  }
}

export function useFilterDT(props: Props) {
  const {
    queryIdNames = [],
    actions,
    filterDependencyKey,
    defaultSize = 10,
    provideSPSS = true, // SPSS stands for: search, page, sort, size. If 'true',
    // then SPSS will be added to the query and provided as fetching params within the filter request.
    // You can also specify which one you want to add to the query by providing an Object
    defaultQueryValue = {},
  } = props
  const { pathname, query, location } = useSelector(routerSelector as ((state: any) => ({ query: Record<string, string>, location: { search: string, query: Record<string, string> }, pathname: string })))
  const dispatch = useDispatch()
  const history = useHistory()

  const search = propOr<string, Query, Query['search']>('', 'search', query)
  const page = +propOr<number, Query, number>(0, 'page', query)
  const sort = propOr<string, Query, Query['sort']>('', 'sort', query)
  const size = propOr<number, Query, Query['size']>(defaultSize, 'size', query)

  const queryIds = useMemo(() => {
    return map<IQueryIds, IQueryIds>(id => {
      // return typeof id === 'number' && !isNaN(id) ? +id : id
      return !isEmpty(id) && !isNaN(<number>id) ? +(<number>id) : id
    }, <IQueryIds>parse(location.search, { ignoreQueryPrefix: true }))
  }, [location.search])

  const cache = useRef<ICache<IQueryIds, typeof queryIdNames>>({
    spss: {},
    queryIds: Object.fromEntries(queryIdNames.flatMap<IQueryIdsTuple<IQueryIds>>(item => [[item], [undefined]])),
    fetched: [],
  })

  // const filterDependencies =
  //   filterDependencyKey &&
  //   Array.isArray(filterDependencyKey) &&
  //   !isEmpty(filterDependencyKey) &&
  //   Object.fromEntries(filterDependencyKey.flatMap<IQueryIdsTuple<IQueryIds>>(item => [[item], [undefined]]))

  // useMemo(() => {
  //   if (filterDependencyKey && Array.isArray(filterDependencyKey) && !isEmpty(filterDependencyKey)) {
  //     return Object.fromEntries(filterDependencyKey.flatMap<IQueryIdsTuple<IQueryIds>>(item => [[item], [undefined]]))
  //   }
  // }, [filterDependencyKey?.length, query])

  useEffect(() => {
    const initialFetchData = prop('initialFetchData', actions)
    if (initialFetchData) {
      if (Array.isArray(initialFetchData)) {
        initialFetchData.forEach(action => {
          dispatch(action())
        })
      } else {
        const action = initialFetchData
        const params = propOr({}, 'initialFetchDataParams', actions)
        dispatch(action(params))
      }
      return () => {
        const initialFetchDataClear = propOr<() => void, Actions, Action>(() => { }, 'initialFetchDataClear', actions)
        if (Array.isArray(initialFetchDataClear)) {
          initialFetchDataClear.forEach(action => {
            dispatch(action())
          })
        } else {
          dispatch(initialFetchDataClear())
        }
      }
    }
  }, [])

  useEffect(() => {
    if (defaultQueryValue && !isEmpty(defaultQueryValue)) {
      const filteredDefaultQueryValue = pickBy<typeof defaultQueryValue, typeof defaultQueryValue>((value, key) => {
        return !prop(key, queryIds)
      }, defaultQueryValue || {})
      !isEmpty(filteredDefaultQueryValue) &&
        history.push({
          pathname,
          search: queryString.stringify(filteredDefaultQueryValue),
        })
    }
  }, [])

  useEffect(() => {
    const cachedQueryIds = cache.current.queryIds
    const cachedSPSS = cache.current.spss
    const ids = omit(['search', 'size', 'page', 'sort'], queryIds)
    const idsByDependencies = Array.isArray(filterDependencyKey) ? pick(filterDependencyKey, ids) : typeof filterDependencyKey === 'string' ? pick([filterDependencyKey], ids) : ids
    const cachedIdsByDependencies = Array.isArray(filterDependencyKey) ? pick(filterDependencyKey, cachedQueryIds) : typeof filterDependencyKey === 'string' ? pick([filterDependencyKey], cachedQueryIds) : cachedQueryIds
    const spss = pick(['search', 'size', 'page', 'sort'], queryIds)
    const initialFetch = cache.current.initialFetch
    const processedParams = getQueryParams({
      provideSPSS,
      getPageParams: getPage({
        ...queryIds,
        search: decodeURI(search),
        page,
        sort: decodeURIComponent(sort),
        size,
      }),
      queryIds,
    })
    if (!areObjsEq(cachedIdsByDependencies, idsByDependencies)) {
      filterByDependencies({ processedParams, cache, queryIds: ids })
    } else if (
      !areObjsEq(cachedSPSS, spss) ||
      (!initialFetch &&
        isEmpty(queryIds) &&
        (!filterDependencyKey || (filterDependencyKey && isEmpty(filterDependencyKey))))
      // (initialListFetch && isEmpty(queryIds))
    ) {
      filterBySPSS({ processedParams, cache, queryIds })
    }
    fetchFields()
    // cache.current.queryIds = { ...cache.current.queryIds, ...ids }
  }, [query])

  useEffect(() => {
    return () => {
      dispatch(actions.listClear())
      forEach<typeof queryIdNames[number]>(name => {
        // if (path(['current', name], cache)) {
        const action = path<FilterFieldActions['clearAction']>([name, 'clearAction'], actions)
        if (action && typeof action === 'function') {
          dispatch(action())
        } else if (action && Array.isArray(action)) {
          forEach<Action>(act => {
            dispatch(act())
          })(action)
        }
        // }
      })(queryIdNames)
    }
  }, [])

  function fetchFields() {
    const initialFetch = path<typeof cache.current.initialFetch>(['current', 'initialFetch'], cache)
    const fetched = pathOr<typeof cache.current.fetched>([], ['current', 'fetched'], cache)
    forEach(name => {
      const deltaQueryId =
        // queryIds[name] &&
        !equals<typeof cache.current.queryIds[string]>(
          path<typeof cache.current.queryIds[string]>(['current', name], cache),
          queryIds[name]
        )
      if (deltaQueryId) {
        const action = path<FilterFieldActions['fetchAction']>([name, 'fetchAction'], actions)
        const params = pathOr<FilterFieldActions['params']>({}, [name, 'params'], actions)

        if (action && typeof action === 'function') {
          const fetchFuncName = action.name
          const deltaFetched = includes(fetchFuncName, fetched)
          if (!initialFetch && !deltaFetched) {
            dispatch(action({ ...omit(['search', 'page', 'sort', 'size'], query), ...params }))
            cache.current.fetched.push(fetchFuncName)
          } else if (initialFetch && deltaQueryId) {
            dispatch(action({ ...omit(['search', 'page', 'sort', 'size'], query), ...params }))
          }
        } else if (Array.isArray(action)) {
          forEach<Action>(act => {
            const fetchFuncName = act.name
            const deltaFetched = !includes(fetchFuncName, fetched)
            if (!initialFetch && deltaFetched) {
              dispatch(act({ ...omit(['search', 'page', 'sort', 'size'], query), ...params }))
              cache.current.fetched.push(fetchFuncName)
            } else if (initialFetch && deltaQueryId) {
              dispatch(act({ ...omit(['search', 'page', 'sort', 'size'], query), ...params }))
            }
          })(action)
        }
        if (name in queryIds) {
          cache.current[name] = queryIds[name]
        }
      }
    }, queryIdNames)
    cache.current.initialFetch = true
  }

  function getQueryParams({ provideSPSS, getPageParams, queryIds }: GetQueryParamsArgsTypes) {
    return pipe(arg => {
      const getPageParams = head(arg)
      if (provideSPSS && typeof provideSPSS !== 'object') {
        return getPageParams
      } else if (provideSPSS && typeof provideSPSS === 'object') {
        return pipe(queryParams => {
          const obj = { ...queryParams }
          for (const queryParamsKey in <{[key: string]: string | boolean}>provideSPSS) {
            if (provideSPSS.hasOwnProperty(queryParamsKey)) {
              if (!isNil(prop<string, { [key: string]: boolean }>(queryParamsKey, provideSPSS))) {
                !prop(queryParamsKey, provideSPSS) && delete obj[queryParamsKey]
              }
            }
          }
          return obj
        })(getPageParams)
      } else if (!provideSPSS) {
        return { ...queryIds }
      }
    })([getPageParams])
  }

  type FilterByDependenciesArgsTypes = {
    processedParams: ReturnType<typeof getQueryParams>
    cache: typeof cache
    queryIds: typeof queryIds
  }

  function filterByDependencies({ processedParams, cache, queryIds = {} }: FilterByDependenciesArgsTypes) {
    const cachedQueryIds = cache.current.queryIds
    if (cachedQueryIds) {
      if (filterDependencyKey) {
        if (Array.isArray(filterDependencyKey)) {
          forEach<string>(key => {
            if (prop(key, queryIds)) {
              if (!equals(prop(key, queryIds), cache.current?.queryIds[key])) {
                dispatch(actions.filterAction(processedParams))
                cache.current.queryIds[key] = queryIds[key]
              }
            } else delete cache.current.queryIds[key]
          })(filterDependencyKey)
        } else if (queryIds[filterDependencyKey]) {

          dispatch(actions.filterAction(processedParams))
          cache.current.queryIds[filterDependencyKey] = queryIds[filterDependencyKey]
        }
      } else if (!areObjsEq(cachedQueryIds, queryIds)) {
        prop('filterAction', actions) && dispatch(actions.filterAction(processedParams))
        cache.current.queryIds = { ...queryIds }
      }
    }
    // else {
    //   prop('filterAction', actions) && dispatch(actions.filterAction(processedParams))
    //   cache.current.queryIds = queryIds
    // }
  }

  function filterBySPSS({ processedParams, cache }: FilterByDependenciesArgsTypes) {
    const deltaSPSS =
      search !== cache.current?.spss.search ||
      page !== cache.current?.spss.page ||
      sort !== cache.current?.spss.sort ||
      size !== cache.current?.spss.size

    if (deltaSPSS) {
      dispatch(actions.filterAction(processedParams))
      const deltaSearch = search !== cache.current?.spss.search ? { search } : {}
      const deltaPage = page !== cache.current?.spss.page ? { page } : {}
      const deltaSort = sort !== cache.current?.spss.sort ? { sort } : {}
      const deltaSize = size !== cache.current?.spss.size ? { size } : {}
      cache.current.spss = {
        ...deltaSearch,
        ...deltaPage,
        ...deltaSort,
        ...deltaSize,
      }
    }
  }

  function defineQuery({
    queryValue,
    queryName,
  }: {
    queryValue: typeof search | typeof page | typeof sort | typeof size
    queryName: string
  }) {
    if (typeof queryValue === 'string') {
      return queryValue
        ? { [queryName]: decodeURIComponent(queryValue) }
        : !provideSPSS ||
          (typeof provideSPSS === 'object' &&
            equals(type(prop(queryName, provideSPSS)), 'Boolean') &&
            !prop(queryName, provideSPSS))
          ? {}
          : { [queryName]: decodeURIComponent(queryValue) }
    }
  }

  function queryPush(value: string | number | undefined, id: string, newQuery: Router['query']) {
    const searchQuery = defineQuery({ queryValue: search, queryName: 'search' })
    const sortQuery = defineQuery({ queryValue: sort, queryName: 'sort' })
    const pageQuery = defineQuery({ queryValue: page, queryName: 'page' })
    const sizeQuery =
      !provideSPSS ||
        (typeof provideSPSS === 'object' &&
          equals(type(prop('size', provideSPSS)), 'Boolean') &&
          !prop('size', provideSPSS))
        ? {}
        : { size }

    const queryObj = value
      ? { ...(newQuery && typeof newQuery === 'object' ? newQuery : queryIds), ...searchQuery, ...sortQuery, ...sizeQuery, ...pageQuery, [id]: value }
      : { ...dissoc(id, (newQuery && typeof newQuery === 'object' ? newQuery : queryIds)), ...searchQuery, ...sortQuery, ...pageQuery, ...sizeQuery }

    // cache.current.queryIds[id] = value

    history.push({
      pathname,
      search: queryString.stringify(queryObj, { arrayFormat: 'comma' }),
    })
  }
  return [queryIds, queryPush, query, pathname]
}
