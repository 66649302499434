import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT,
  ASSESSMENT_ATTEMPT,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_BULK,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_CRITERIA,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_LIST,
  ASSESSMENT_STUDENT,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_SUBMIT,
  ATTEMPT_DELETE,
  GLOBAL_STATE,
} from '../constants/state'

export const assessmentSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT]), data => data)

export const assessmentBulkSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_BULK]), data => data)

export const assessmentListSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_LIST]), data => data)

export const assessmentAttemptItemSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_ATTEMPT_ITEM]), data => data)

export const assessmentCriteriaSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_CRITERIA]), data => data)

export const assessmentCriteriaUpdateSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_CRITERIA_UPDATE]),
  data => data
)

export const assessmentCriteriaListSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_CRITERIA_LIST]),
  data => data
)

export const assessmentAttemptSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_ATTEMPT]), data => data)

export const assessmentCopySelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_COPY]), data => data)

export const assessmentSubmitSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_SUBMIT]), data => data)

export const attemptDeleteSelector = createSelector(path([GLOBAL_STATE, ATTEMPT_DELETE]), data => data)

export const assessmentCriteriaTypesSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_CRITERIA_TYPES]),
  data => data
)

export const assessmentStudentUploadSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_STUDENT_UPLOAD]),
  data => data
)

export const assessmentStudentSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_STUDENT]), data => data)

export const assessmentCreateSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_CREATE]), data => data)

export const assessmentSelectors = {
  assessmentSelector,
  assessmentBulkSelector,
  assessmentListSelector,
  assessmentAttemptItemSelector,
  assessmentCriteriaSelector,
  assessmentCriteriaListSelector,
  assessmentAttemptSelector,
  assessmentCopySelector,
  assessmentSubmitSelector,
  attemptDeleteSelector,
  assessmentCriteriaTypesSelector,
}
