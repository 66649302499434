import { assessmentOffenceSaga } from '@module/AcademicMisconduct/redux/saga'
import { academicYearSaga } from '@module/AcademicYears/redux/saga'
import { accountSaga } from '@module/Account/redux/saga'
import { achievementRecordsSaga } from '@module/AchievementRecords/redux/saga'
import { assessmentSaga } from '@module/Assessment/redux/saga'
import { assessmentECSaga } from '@module/AssessmentEC/redux/saga'
import { assessmentMarkSaga } from '@module/AssessmentMarks/redux/saga'
import { attendanceSaga } from '@module/Attendance/redux/saga'
import { calendarEventSaga } from '@module/Calendar/redux/saga'
import { categorySaga } from '@module/Category/redux/saga'
import { citySaga } from '@module/City/redux/saga'
import { mailSaga } from '@module/ComposeEmail/redux/saga'
import { contractAgreementSaga } from '@module/ContractInformation/redux/saga'
import { countrySaga } from '@module/Country/redux/saga'
import { courseSaga } from '@module/Course/activate/redux/saga'
import { courseCreateSaga } from '@module/Course/create/redux/saga'
import { departmentsSaga } from '@module/Departments/redux/saga'
import { educationPlaceSaga } from '@module/EducationPlace/redux/saga'
import { enrollmentSaga } from '@module/Enrollment/redux/saga'
import { feedbackSagas } from '@module/Feedback/redux/saga'
import { moduleFilesSaga } from '@module/Files/ModuleFiles/redux/saga'
import { formSaga } from '@module/Forms/redux/saga'
import { generalAdmissionSaga } from '@module/GeneralAdmission/redux/saga'
import { groupSaga } from '@module/Groups/redux/saga'
import { guestUsersSaga } from '@module/GuestUsers/redux/saga'
import { hrAdmissionSaga } from '@module/HRAdmission/redux/saga'
import { reportInquirySagas } from '@module/Inquiry/redux/saga'
import { videoTutorialSaga } from '@module/IntroVideos/redux/saga'
import { jobPositionSaga } from '@module/JobPosition/redux/saga'
import { lessonSaga } from '@module/Lesson/redux/saga'
import { eLibrarySaga } from '@module/Library/redux/saga'
import { modulesOutcomeSaga } from '@module/ModuleOutcome/redux/saga'
import { cclsModuleSaga } from '@module/Modules/activate/redux/saga'
import { moduleSaga } from '@module/Modules/create/redux/saga'
import { userOpenLinkSaga } from '@module/OpenLink/redux/saga'
import { playerSaga } from '@module/Player/redux/saga'
import { levelSaga } from '@module/QualityAssurance/redux/level/saga'
import { reportSaga } from '@module/Reports/redux/saga'
import rolesSaga from '@module/Roles/redux/saga'
import { semesterSaga } from '@module/Semester/redux/saga'
import { stCourseAdmissionSaga } from '@module/ShortCourseAdmission/redux/saga'
import { staffSaga } from '@module/Staff/redux/saga'
import { stateSaga } from '@module/State/redux/saga'
import { studentSaga } from '@module/Student/redux/saga'
import { admissionSaga } from '@module/StudentAdmission/redux/saga'
import { studentDashboard } from '@module/StudentDashboard/redux/saga'
import { vacancyPositionSaga } from '@module/VacancyPosition/redux/saga'
import * as Sentry from '@sentry/react'
import { all } from 'redux-saga/effects'

export default function* rootSaga() {
  try {
    yield all([
      accountSaga(),
      rolesSaga(),
      academicYearSaga(),
      lessonSaga(),
      courseSaga(),
      levelSaga(),
      semesterSaga(),
      cclsModuleSaga(),
      moduleSaga(),
      calendarEventSaga(),
      groupSaga(),
      staffSaga(),
      courseCreateSaga(),
      studentSaga(),
      enrollmentSaga(),
      assessmentSaga(),
      assessmentMarkSaga(),
      assessmentOffenceSaga(),
      assessmentECSaga(),
      reportSaga(),
      modulesOutcomeSaga(),
      moduleFilesSaga(),
      achievementRecordsSaga(),
      attendanceSaga(),
      studentDashboard(),
      formSaga(),
      userOpenLinkSaga(),
      playerSaga(),
      videoTutorialSaga(),
      departmentsSaga(),
      hrAdmissionSaga(),
      citySaga(),
      countrySaga(),
      stateSaga(),
      jobPositionSaga(),
      mailSaga(),
      stCourseAdmissionSaga(),
      categorySaga(),
      eLibrarySaga(),
      admissionSaga(),
      generalAdmissionSaga(),
      educationPlaceSaga(),
      vacancyPositionSaga(),
      guestUsersSaga(),
      contractAgreementSaga(),
      feedbackSagas(),
      reportInquirySagas(),
    ])
  } catch (err) {
    Sentry.captureException(
      err?.message ||
        'Error in on of the sagas (This is a mock error message due to the absence of the original error message)'
    )
  }
}
