import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.ASSESSMENT]: createState(actionTypes.ASSESSMENT),
  [STATE.ASSESSMENT_CREATE]: createState(actionTypes.ASSESSMENT_CREATE),
  [STATE.ASSESSMENT_BULK]: createState(actionTypes.ASSESSMENT_BULK),
  [STATE.ASSESSMENT_LIST]: createState(actionTypes.ASSESSMENT_LIST),
  [STATE.ASSESSMENT_COPY]: createState(actionTypes.ASSESSMENT_COPY),
  [STATE.ASSESSMENT_CRITERIA]: createState(actionTypes.ASSESSMENT_CRITERIA),
  [STATE.ASSESSMENT_CRITERIA_UPDATE]: createState(actionTypes.ASSESSMENT_CRITERIA_UPDATE),
  [STATE.ASSESSMENT_CRITERIA_LIST]: createState(actionTypes.ASSESSMENT_CRITERIA_LIST),
  [STATE.ASSESSMENT_ATTEMPT]: createState(actionTypes.ASSESSMENT_ATTEMPT),
  [STATE.ASSESSMENT_ATTEMPT_ITEM]: createState(actionTypes.ASSESSMENT_ATTEMPT_ITEM),
  [STATE.ASSESSMENT_SUBMIT]: createState(actionTypes.ASSESSMENT_SUBMIT),
  [STATE.ATTEMPT_DELETE]: createState(actionTypes.ATTEMPT_DELETE),
  [STATE.ASSESSMENT_CRITERIA_TYPES]: createState(actionTypes.ASSESSMENT_CRITERIA_TYPES),
  [STATE.ASSESSMENT_STUDENT_UPLOAD]: createState(actionTypes.ASSESSMENT_STUDENT_UPLOAD),
  [STATE.ASSESSMENT_STUDENT]: createState(actionTypes.ASSESSMENT_STUDENT),
})

export const ASSESSMENT = STATE.GLOBAL_STATE

export default reducer
