import actionTypes, {
  CCLS_MODULE,
  CCLS_MODULE_ALL,
  CCLS_MODULE_CREATE,
  CCLS_MODULE_DELETE,
  CCLS_MODULE_LIST,
  CCLS_MODULE_TEACHER_UPDATE,
  CCLS_MODULES_FILTER,
} from '../constants/actionTypes'

export const cclsModule = payload => ({
  type: actionTypes[CCLS_MODULE].pending,
  payload,
})

export const cclsModulesFilter = payload => ({
  type: actionTypes[CCLS_MODULES_FILTER].pending,
  payload,
})

export const cclsModulesFilterClear = () => ({
  type: actionTypes[CCLS_MODULES_FILTER].clear,
})

export const cclsModuleClear = () => ({
  type: actionTypes[CCLS_MODULE].clear,
})

export const cclsModuleCreate = payload => ({
  type: actionTypes[CCLS_MODULE_CREATE].pending,
  payload,
})

export const cclsModuleTeacherUpdate = payload => ({
  type: actionTypes[CCLS_MODULE_TEACHER_UPDATE].pending,
  payload,
})

export const cclsModuleDelete = payload => ({
  type: actionTypes[CCLS_MODULE_DELETE].pending,
  payload,
})

export const cclsModuleList = payload => ({
  type: actionTypes[CCLS_MODULE_LIST].pending,
  payload,
})

export const cclsModuleListClear = () => ({
  type: actionTypes[CCLS_MODULE_LIST].clear,
})

export const cclsModuleAll = payload => ({
  type: actionTypes[CCLS_MODULE_ALL].pending,
  payload,
})

export const cclsModuleAllClear = () => ({
  type: actionTypes[CCLS_MODULE_ALL].clear,
})
