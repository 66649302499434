// ** React Imports
import '@assets/scss/style.scss'
// ** Ripple Button
import '@components/ripple-button'
// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner'
// ** Toast & ThemeColors Context
import ability from '@configs/acl/ability'
// ** Redux Imports
import { useAppSelector } from '@hooks'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { authUserInfo } from '@store/selector'
import { createStore, history } from '@store/store'
import { sentryInit } from '@utility/Sentry/init'
import { AbilityContext } from '@utility/context/Can'
// ** Intl
import { IntlProviderWrapper } from '@utility/context/Internationalization'
import { ThemeContext } from '@utility/context/ThemeColors'
import { UserSettingsProvider } from '@utility/context/UserSettings'
// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
// import 'prismjs/themes/prism-tomorrow.css'
import { propOr } from 'ramda'
import { Suspense, lazy, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { AlertCircle } from 'react-feather'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Alert } from 'reactstrap'

// ** Core styles
import '@core/assets/fonts/feather/iconfont.css'
import '@core/styles/core.scss'
// ** React Toastify
import '@core/styles/react/libs/toastify/toastify.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const isProduction = process.env.NODE_ENV === 'production'

const SentryWrapper = props => {
  const userInfo = useAppSelector(authUserInfo)
  const children = propOr(null, 'children', props)

  useEffect(() => {
    if (userInfo && isProduction) {
      Sentry.setContext('userInfo', userInfo)
    }
  }, [userInfo?.id])

  useEffect(() => {
    if (isProduction) {
      sentryInit({
        configs: {
          release: process.env.REACT_APP_SENTRY_RELEASE,
          integrations: [
            history
              ? new BrowserTracing({
                  routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                })
              : new BrowserTracing(),
          ],
          normalizeDepth: 6,
          beforeSend(event, hint) {
            return event
          },
          tracesSampler: samplingContext => {
            // if ('...') {
            //   // Drop this transaction, by setting its sample rate to 0%
            //   return 0
            // } else {
            //   // Default sample rate for all others (replaces tracesSampleRate)
            //   return 0.1
            // }
            return 0.1
          },
        },
      })
    }
  }, [])

  const errorFallback = (
    <Alert color='danger'>
      <div className='alert-body'>
        <AlertCircle size={15} /> <span className='ms-1'>An error has occurred.</span>
      </div>
    </Alert>
  )

  return <Sentry.ErrorBoundary fallback={errorFallback}>{children}</Sentry.ErrorBoundary>
}

const Wrapper = () => {
  return (
    <Provider store={createStore()}>
      <SentryWrapper>
        <IntlProviderWrapper defaultLocale='en'>
          <UserSettingsProvider>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <LazyApp />
                  <ToastContainer newestOnTop />
                </ThemeContext>
              </AbilityContext.Provider>
            </Suspense>
          </UserSettingsProvider>
        </IntlProviderWrapper>
      </SentryWrapper>
    </Provider>
  )
}

ReactDOM.render(<Wrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
