export const ASSESSMENT = '/assessment/'
export const ASSESSMENT_BULK = `${ASSESSMENT}bulk`
export const ASSESSMENT_ATTEMPT = `${ASSESSMENT}attempt/`
export const ASSESSMENT_COPY = `${ASSESSMENT}copy/`
export const ASSESSMENT_SUBMIT = `${ASSESSMENT}submit`
export const ASSESSMENT_STUDENT_UPLOAD = `${ASSESSMENT}homework`
export const ASSESSMENT_CRITERIA = `${ASSESSMENT}criteria`
export const ASSESSMENT_CRITERIA_TYPES = `${ASSESSMENT_CRITERIA}/types`
export const ASSESSMENT_CRITERIA_LIST = `${ASSESSMENT_CRITERIA}/list`
export const ASSESSMENT_STUDENT = `${ASSESSMENT}all`
