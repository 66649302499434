import { ErrorToast, SuccessToast } from '@components/Toasts'
import { WatchSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { path, pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ASSESSMENT,
  ASSESSMENT_ATTEMPT,
  ASSESSMENT_ATTEMPT_DELETE,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_BULK,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_CRITERIA,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_DELETE,
  ASSESSMENT_LIST,
  ASSESSMENT_STUDENT,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_SUBMIT,
  ATTEMPT_DELETE,
} from '../constants/actionTypes'

function watchAssessment() {
  return watchSaga({
    action: actionTypes[ASSESSMENT],
    api: API.assessment,
  })
}

function watchAssessmentCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_CREATE],
    api: API.assessmentCreate,
  })
}

function* watchAssessmentCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_CREATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
    yield toast.success(<SuccessToast />)
  })
}

function* watchAssessmentCreateRejected() {
  yield takeEvery(actionTypes[ASSESSMENT_CREATE].rejected, function* (action) {
    const description = path(['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

function watchAssessmentDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_DELETE],
    api: API.assessmentDelete,
  })
}

function watchAssessmentList() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_LIST],
    api: API.assessmentList,
  })
}

function watchAssessmentBulk() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_BULK],
    api: API.assessmentBulk,
  })
}

function watchAssessmentBulkFulFilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_BULK],
    message: {
      title: 'Success',
      description: 'Mark inserted',
    },
    filter: {
      takeActionFromPayload: true,
    },
  })
}

function watchAssessmentBulkRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_BULK],
    message: true,
  })
}

const assessmentAttempt = new WatchSaga({ actionType: actionTypes[ASSESSMENT_ATTEMPT], api: API.assessmentAttempt })

// function watchAssessmentAttempt() {
//   return watchSaga({
//     action: actionTypes[ASSESSMENT_ATTEMPT],
//     api: API.assessmentAttempt,
//   })
// }

// function watchAssessmentAttemptRejected() {
//   return watchSagaRejected({
//     action: actionTypes[ASSESSMENT_ATTEMPT],
//     message: true,
//   })
// }

// function* watchAssessmentAttemptFulFilled() {
//   yield takeEvery(actionTypes[ASSESSMENT_ATTEMPT].fulfilled, function* (payload) {
//     const fn = path(['args', 'fn'], payload) || (() => {})
//     fn()
//     toast.success(<SuccessToast text='Verified' />)
//   })
// }

function watchGetAssessmentAttemptById() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_ATTEMPT_ITEM],
    api: API.getAssessmentAttemptById,
  })
}

function watchGetAssessmentAttemptByIdRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_ATTEMPT_ITEM],
    message: true,
  })
}

const attemptDelete = new WatchSaga({
  actionType: actionTypes[ASSESSMENT_ATTEMPT_DELETE],
  api: API.assessmentAttemptDelete,
})

// function watchAssessmentAttemptDelete() {
//   return watchSaga({
//     action: actionTypes[ASSESSMENT_ATTEMPT_DELETE],
//     api: API.assessmentAttemptDelete,
//   })
// }

// function watchAssessmentAttemptDeleteFulFilled() {
//   return watchSagaFulfilled({
//     action: actionTypes[ASSESSMENT_ATTEMPT_DELETE],
//     message: {
//       title: 'Successfully deleted',
//     },
//     shouldDoFulfilledFunction: true,
//   })
// }

// function watchAssessmentAttemptDeleteRejected() {
//   return watchSagaRejected({
//     action: actionTypes[ASSESSMENT_ATTEMPT_DELETE],
//     message: true,
//   })
// }

function watchAssessmentCopy() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_COPY],
    api: API.assessmentCopy,
  })
}

function watchAssessmentCopyFulFilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_COPY],
    message: {
      title: 'Success',
      description: 'Marks successfully copied',
    },
  })
}

function watchAssessmentCopyRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_COPY],
    message: true,
  })
}

function watchAssessmentSubmit() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_SUBMIT],
    api: API.assessmentSubmit,
  })
}

function* watchAssessmentSubmitFulFilled() {
  yield takeEvery(actionTypes[ASSESSMENT_SUBMIT].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
  })
}

function* watchAssessmentSubmitRejected() {
  yield takeEvery(actionTypes[ASSESSMENT_SUBMIT].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchAssessmentCriteria() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_CRITERIA],
    api: API.assessmentCriteria,
  })
}
function watchAssessmentCriteriaList() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_CRITERIA_LIST],
    api: API.assessmentCriteriaList,
  })
}

const attemptDeleteSaga = new WatchSaga({ actionType: actionTypes[ATTEMPT_DELETE], api: API.attemptDelete })

function watchAssessmentCriteriaTypes() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_CRITERIA_TYPES],
    api: API.assessmentCriteriaTypes,
  })
}

function* watchAssessmentCriteriaTypesFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_CRITERIA_TYPES].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function watchAssessmentCriteriaTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_CRITERIA_TYPES],
    message: true,
  })
}

function watchAssessmentCriteriaUpdate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_CRITERIA_UPDATE],
    api: API.assessmentCriteriaUpdate,
  })
}

function* watchAssessmentCriteriaUpdateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_CRITERIA_UPDATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchAssessmentCriteriaUpdateRejected() {
  yield takeEvery(actionTypes[ASSESSMENT_CRITERIA_UPDATE].rejected, function* (action) {
    const description = path(['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

function watchAssessmentUpload() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_STUDENT_UPLOAD],
    api: API.assessmentStudentUpload,
  })
}

function* watchAssessmentUploadFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_STUDENT_UPLOAD].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchAssessmentUploadRejected() {
  yield takeEvery(actionTypes[ASSESSMENT_STUDENT_UPLOAD].rejected, function* (action) {
    const description = path(['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

function watchAssessmentStudent() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_STUDENT],
    api: API.assessmentStudent,
  })
}

function* watchAssessmentStudentFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_STUDENT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchAssessmentStudentRejected() {
  yield takeEvery(actionTypes[ASSESSMENT_STUDENT].rejected, function* (action) {
    const description = path(['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

export function* assessmentSaga() {
  yield all([
    fork(watchAssessment),
    fork(watchAssessmentCreate),
    fork(watchAssessmentCreateFulfilled),
    fork(watchAssessmentCreateRejected),
    fork(watchAssessmentDelete),
    fork(watchAssessmentList),
    fork(watchAssessmentBulk),
    fork(watchAssessmentBulkFulFilled),
    fork(watchAssessmentBulkRejected),
    fork(watchGetAssessmentAttemptById),
    fork(watchGetAssessmentAttemptByIdRejected),
    fork(assessmentAttempt.watch),
    fork(assessmentAttempt.watchRejected),
    fork(assessmentAttempt.watchFulfilled),
    fork(attemptDelete.watch),
    fork(attemptDelete.watchFulfilled),
    fork(attemptDelete.watchRejected),
    fork(watchAssessmentCopy),
    fork(watchAssessmentCopyFulFilled),
    fork(watchAssessmentCopyRejected),
    fork(watchAssessmentSubmit),
    fork(watchAssessmentSubmitFulFilled),
    fork(watchAssessmentSubmitRejected),
    fork(watchAssessmentCriteria),
    fork(watchAssessmentCriteriaList),
    fork(attemptDeleteSaga.watch),
    fork(attemptDeleteSaga.watchFulfilled),
    fork(attemptDeleteSaga.watchRejected),
    fork(watchAssessmentCriteriaTypes),
    fork(watchAssessmentCriteriaTypesFulfilled),
    fork(watchAssessmentCriteriaTypesRejected),
    fork(watchAssessmentCriteriaUpdate),
    fork(watchAssessmentCriteriaUpdateFulfilled),
    fork(watchAssessmentCriteriaUpdateRejected),
    fork(watchAssessmentUpload),
    fork(watchAssessmentUploadFulfilled),
    fork(watchAssessmentUploadRejected),
    fork(watchAssessmentStudent),
    fork(watchAssessmentStudentFulfilled),
    fork(watchAssessmentStudentRejected),
  ])
}
