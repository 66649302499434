import { createAction } from '@helpers/createAction'

export const ASSESSMENT = 'ASSESSMENT'
export const ASSESSMENT_CREATE = 'ASSESSMENT_CREATE'
export const ASSESSMENT_DELETE = 'ASSESSMENT_DELETE'
export const ASSESSMENT_LIST = 'ASSESSMENT_LIST'
export const ASSESSMENT_BULK = 'ASSESSMENT_BULK'
export const ASSESSMENT_ATTEMPT = 'ASSESSMENT_ATTEMPT'
export const ASSESSMENT_ATTEMPT_ITEM = 'ASSESSMENT_ATTEMPT_ITEM'
export const ASSESSMENT_ATTEMPT_DELETE = 'ASSESSMENT_ATTEMPT_DELETE'
export const ASSESSMENT_SUBMIT = 'ASSESSMENT_SUBMIT'
export const ASSESSMENT_COPY = 'ASSESSMENT_COPY'
export const ASSESSMENT_CRITERIA = 'ASSESSMENT_CRITERIA'
export const ASSESSMENT_CRITERIA_UPDATE = 'ASSESSMENT_CRITERIA_UPDATE'
export const ASSESSMENT_CRITERIA_LIST = 'ASSESSMENT_CRITERIA_LIST'
export const ATTEMPT_DELETE = 'ATTEMPT_DELETE'
export const ASSESSMENT_CRITERIA_TYPES = 'ASSESSMENT_CRITERIA_TYPES'
export const ASSESSMENT_STUDENT_UPLOAD = 'ASSESSMENT_STUDENT_UPLOAD'
export const ASSESSMENT_STUDENT = 'ASSESSMENT_STUDENT'

export default {
  [ASSESSMENT]: createAction(ASSESSMENT),
  [ASSESSMENT_CREATE]: createAction(ASSESSMENT_CREATE),
  [ASSESSMENT_DELETE]: createAction(ASSESSMENT_DELETE),
  [ASSESSMENT_LIST]: createAction(ASSESSMENT_LIST),
  [ASSESSMENT_BULK]: createAction(ASSESSMENT_BULK),
  [ASSESSMENT_ATTEMPT]: createAction(ASSESSMENT_ATTEMPT),
  [ASSESSMENT_ATTEMPT_ITEM]: createAction(ASSESSMENT_ATTEMPT_ITEM),
  [ASSESSMENT_ATTEMPT_DELETE]: createAction(ASSESSMENT_ATTEMPT_DELETE),
  [ASSESSMENT_SUBMIT]: createAction(ASSESSMENT_SUBMIT),
  [ASSESSMENT_COPY]: createAction(ASSESSMENT_COPY),
  [ASSESSMENT_CRITERIA]: createAction(ASSESSMENT_CRITERIA),
  [ASSESSMENT_CRITERIA_UPDATE]: createAction(ASSESSMENT_CRITERIA_UPDATE),
  [ASSESSMENT_CRITERIA_LIST]: createAction(ASSESSMENT_CRITERIA_LIST),
  [ATTEMPT_DELETE]: createAction(ATTEMPT_DELETE),
  [ASSESSMENT_CRITERIA_TYPES]: createAction(ASSESSMENT_CRITERIA_TYPES),
  [ASSESSMENT_STUDENT_UPLOAD]: createAction(ASSESSMENT_STUDENT_UPLOAD),
  [ASSESSMENT_STUDENT]: createAction(ASSESSMENT_STUDENT),
}
