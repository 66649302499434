export const NODE_ENV = process.env.NODE_ENV || 'development'

export const API_SCHEME = NODE_ENV !== 'development' ? ('https' as const) : ('http' as const)
// export const API_SCHEME = NODE_ENV !== 'development' ? 'https' : 'https'
export const API_WS_SCHEME = NODE_ENV !== 'development' ? 'wss' : 'ws'

export const API_LOCAL = '192.168.0.180:9120' //'localhost:9120'
export const API_LIVE = 'api.edulab.app'
export const API_HOST = NODE_ENV !== 'development' ? API_LIVE : API_LOCAL

export const API_PROJECT = '/edulab_udea_demo' as const
export const API_V1 = '/api/v1' as const
export const API_URL = `${API_HOST}${API_PROJECT}${API_V1}` as const
