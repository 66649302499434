import actionTypes, {
  ASSESSMENT,
  ASSESSMENT_ATTEMPT_DELETE,
  ASSESSMENT_ATTEMPT_ITEM,
  ASSESSMENT_ATTEMPT,
  ASSESSMENT_BULK,
  ASSESSMENT_CRITERIA_LIST,
  ASSESSMENT_CRITERIA,
  ASSESSMENT_SUBMIT,
  ASSESSMENT_LIST,
  ASSESSMENT_COPY,
  ASSESSMENT_CREATE,
  ASSESSMENT_DELETE,
  ATTEMPT_DELETE,
  ASSESSMENT_CRITERIA_TYPES,
  ASSESSMENT_STUDENT_UPLOAD,
  ASSESSMENT_CRITERIA_UPDATE,
  ASSESSMENT_STUDENT,
} from '../constants/actionTypes'

export const assessment = payload => ({
  type: actionTypes[ASSESSMENT].pending,
  payload,
})

export const assessmentCreate = payload => ({
  type: actionTypes[ASSESSMENT_CREATE].pending,
  payload,
})

export const assessmentDelete = payload => ({
  type: actionTypes[ASSESSMENT_DELETE].pending,
  payload,
})

export const assessmentList = payload => ({
  type: actionTypes[ASSESSMENT_LIST].pending,
  payload,
})

export const assessmentListClear = () => ({
  type: actionTypes[ASSESSMENT_LIST].clear,
})

export const assessmentBulk = payload => ({
  type: actionTypes[ASSESSMENT_BULK].pending,
  payload,
})

export const assessmentBulkClear = () => ({
  type: actionTypes[ASSESSMENT_BULK].clear,
})

export const assessmentAttempt = payload => ({
  type: actionTypes[ASSESSMENT_ATTEMPT].pending,
  payload,
})

export const getAssessmentAttemptById = payload => ({
  type: actionTypes[ASSESSMENT_ATTEMPT_ITEM].pending,
  payload,
})

export const assessmentAttemptItemClear = () => ({
  type: actionTypes[ASSESSMENT_ATTEMPT_ITEM].clear,
})

export const assessmentAttemptDelete = payload => ({
  type: actionTypes[ASSESSMENT_ATTEMPT_DELETE].pending,
  payload,
})

export const assessmentCopy = payload => ({
  type: actionTypes[ASSESSMENT_COPY].pending,
  payload,
})

export const assessmentSubmit = payload => ({
  type: actionTypes[ASSESSMENT_SUBMIT].pending,
  payload,
})

export const assessmentCriteria = payload => ({
  type: actionTypes[ASSESSMENT_CRITERIA].pending,
  payload,
})

export const assessmentCriteriaUpdate = payload => ({
  type: actionTypes[ASSESSMENT_CRITERIA_UPDATE].pending,
  payload,
})

export const assessmentCriteriaClear = () => ({
  type: actionTypes[ASSESSMENT_CRITERIA].clear,
})

export const assessmentCriteriaList = payload => ({
  type: actionTypes[ASSESSMENT_CRITERIA_LIST].pending,
  payload,
})

export const assessmentCriteriaListClear = () => ({
  type: actionTypes[ASSESSMENT_CRITERIA_LIST].clear,
})

export const attemptDelete = payload => ({
  type: actionTypes[ATTEMPT_DELETE].pending,
  payload,
})

export const assessmentCriteriaTypes = payload => ({
  type: actionTypes[ASSESSMENT_CRITERIA_TYPES].pending,
  payload,
})

export const assessmentCriteriaTypesClear = () => ({
  type: actionTypes[ASSESSMENT_CRITERIA_TYPES].clear,
})

export const assessmentStudentUpload = payload => ({
  type: actionTypes[ASSESSMENT_STUDENT_UPLOAD].pending,
  payload,
})

export const assessmentStudent = payload => ({
  type: actionTypes[ASSESSMENT_STUDENT].pending,
  payload,
})

export const assessmentStudentClear = () => ({
  type: actionTypes[ASSESSMENT_STUDENT].clear,
})
