import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const assessment = data =>
  getInstance().get(`${API.ASSESSMENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const assessmentCreate = data => getInstance().post(API.ASSESSMENT, data)

export const assessmentStudentUpload = data => getInstance().post(API.ASSESSMENT_STUDENT_UPLOAD, data)

export const assessmentDelete = data => getInstance().delete(`${API.ASSESSMENT}${prop('id', data)}`)

export const assessmentList = data =>
  getInstance().get(API.ASSESSMENT, {
    params: data,
  })

export const assessmentBulk = data => getInstance().post(API.ASSESSMENT_BULK, data)

export const assessmentAttempt = data => getInstance().post(API.ASSESSMENT_ATTEMPT, data)

export const getAssessmentAttemptById = data =>
  getInstance().get(API.ASSESSMENT_ATTEMPT, {
    params: data,
  })

export const assessmentAttemptDelete = data => getInstance().delete(`${API.ASSESSMENT_ATTEMPT}${prop('id', data)}`)

export const assessmentCopy = data => getInstance().post(API.ASSESSMENT_COPY, data)

export const assessmentSubmit = data => getInstance().post(API.ASSESSMENT_SUBMIT, data)

export const assessmentCriteria = data =>
  getInstance().get(API.ASSESSMENT_CRITERIA, {
    params: data,
  })

export const assessmentCriteriaUpdate = data => getInstance().post(API.ASSESSMENT_CRITERIA, data)

export const assessmentCriteriaList = data =>
  getInstance().get(API.ASSESSMENT_CRITERIA_LIST, {
    params: data,
  })

export const attemptDelete = data =>
  getInstance().delete(API.ASSESSMENT_ATTEMPT, {
    params: data,
  })

export const assessmentCriteriaTypes = data =>
  getInstance().get(API.ASSESSMENT_CRITERIA_TYPES, {
    params: data,
  })

export const assessmentStudent = data =>
  getInstance().get(API.ASSESSMENT_STUDENT, {
    params: data,
  })
